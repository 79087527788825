import React, {memo, useEffect, useState} from "react"
import style from "styles/playtoearn.module.css"
import SwiperCore, {Navigation} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import NftSlide from './NftSlide'
import useLocale from '../../../../hooks/useLocale'

SwiperCore.use([Navigation])


const NFT = () => {
	const {nftSection} = useLocale()
	const [slider, setSlider] = useState<SwiperCore>()
	const [currentIndex, setCurrentIndex] = useState(0)
	const [numOfSlides, setNumOfSlides] = useState(0)

	useEffect(() => {
		const width = window.innerWidth
		if (width <= 460) {
			setNumOfSlides(1)
			setCurrentIndex(4)
		} else if (width <= 1024) {
			setNumOfSlides(3)
		} else setNumOfSlides(5)
	}, [])

	useEffect(() => {
		if (slider) {
			const width = window.innerWidth
			if (width <= 460) {
				slider?.slideTo(1)
			} else if (width <= 1024) {
				slider?.slideTo(3)
			} else {
				slider?.slideTo(5)
			}
		}
	}, [slider])


	const videos: { title: string, items: { image: string, title: string, text: string }[] }[] = [
		{
			title: nftSection.redcards,
			items: [
				{image: '/image/nft/red2.webp', title: 'Redcards #5', text: 'NFT 2D Avatars'},
				{image: '/image/nft/red9.webp', title: 'Redcards #6', text: 'NFT 2D Avatars'},
				{image: '/image/nft/red5.webp', title: 'Redcards #7', text: 'NFT 2D Avatars'},
				{image: '/image/nft/red1.webp', title: 'Redcards #9', text: 'NFT 2D Avatars'},
				{image: '/image/nft/red11.webp', title: 'Redcards #11', text: 'NFT 2D Avatars'},
				{image: '/image/nft/red6.webp', title: 'Redcards #15', text: 'NFT 2D Avatars'},
				{image: '/image/nft/red8.webp', title: 'Redcards #17', text: 'NFT 2D Avatars'},
				{image: '/image/nft/red10.webp', title: 'Redcards #18', text: 'NFT 2D Avatars'},
				{image: '/image/nft/red4.webp', title: 'Redcards #20', text: 'NFT 2D Avatars'},
				{image: '/image/nft/red7.webp', title: 'Redcards #24', text: 'NFT 2D Avatars'},
				{image: '/image/nft/red3.webp', title: 'Redcards #25', text: 'NFT 2D Avatars'},
			]
		},
		{
			title: nftSection.tribes,
			items: [
				{image: '/image/nft/tribes1.webp', title: 'Tribes #1', text: 'NFT 2D Avatars'},
				{image: '/image/nft/tribes6.webp', title: 'Tribes #3', text: 'NFT 2D Avatars'},
				{image: '/image/nft/tribes10.webp', title: 'Tribes #5', text: 'NFT 2D Avatars'},
				{image: '/image/nft/tribes8.webp', title: 'Tribes #6', text: 'NFT 2D Avatars'},
				{image: '/image/nft/tribes4.webp', title: 'Tribes #7', text: 'NFT 2D Avatars'},
				{image: '/image/nft/tribes5.webp', title: 'Tribes #8', text: 'NFT 2D Avatars'},
				{image: '/image/nft/tribes2.webp', title: 'Tribes #11', text: 'NFT 2D Avatars'},
				{image: '/image/nft/tribes11.webp', title: 'Tribes #12', text: 'NFT 2D Avatars'},
				{image: '/image/nft/tribes9.webp', title: 'Tribes #13', text: 'NFT 2D Avatars'},
				{image: '/image/nft/tribes7.webp', title: 'Tribes #14', text: 'NFT 2D Avatars'},
				{image: '/image/nft/tribes3.webp', title: 'Tribes #15', text: 'NFT 2D Avatars'},
			]
		},
		{
			title: nftSection.aquamen,
			items: [
				{image: '/image/nft/aq4.webp', title: 'Aquamen #15', text: 'NFT 2D Avatars'},
				{image: '/image/nft/aq6.webp', title: 'Aquamen #16', text: 'NFT 2D Avatars'},
				{image: '/image/nft/aq1.webp', title: 'Aquamen #17', text: 'NFT 2D Avatars'},
				{image: '/image/nft/aq2.webp', title: 'Aquamen #18', text: 'NFT 2D Avatars'},
				{image: '/image/nft/aq3.webp', title: 'Aquamen #19', text: 'NFT 2D Avatars'},
				{image: '/image/nft/aq5.webp', title: 'Aquamen #20', text: 'NFT 2D Avatars'},
			]
		},
		{
			title: nftSection.military,
			items: [
				{image: '/image/nft/mil1.webp', title: 'Military #1', text: 'NFT 2D Avatars'},
				{image: '/image/nft/mil6.webp', title: 'Military #4', text: 'NFT 2D Avatars'},
				{image: '/image/nft/mil2.webp', title: 'Military #8', text: 'NFT 2D Avatars'},
				{image: '/image/nft/mil7.webp', title: 'Military #9', text: 'NFT 2D Avatars'},
				{image: '/image/nft/mil3.webp', title: 'Military #10', text: 'NFT 2D Avatars'},
				{image: '/image/nft/mil4.webp', title: 'Military #13', text: 'NFT 2D Avatars'},
				{image: '/image/nft/mil9.webp', title: 'Military #17', text: 'NFT 2D Avatars'},
				{image: '/image/nft/mil8.webp', title: 'Military #18', text: 'NFT 2D Avatars'},
				{image: '/image/nft/mil10.webp', title: 'Military #19', text: 'NFT 2D Avatars'},
				{image: '/image/nft/mil11.webp', title: 'Military #20', text: 'NFT 2D Avatars'},
				{image: '/image/nft/mil5.webp', title: 'Military #24', text: 'NFT 2D Avatars'},
			]
		},
		{
			title: nftSection.punks,
			items: [
				{image: '/image/nft/punk7.webp', title: 'Punks #33', text: 'NFT 2D Avatars'},
				{image: '/image/nft/punk5.webp', title: 'Punks #34', text: 'NFT 2D Avatars'},
				{image: '/image/nft/punk4.webp', title: 'Punks #35', text: 'NFT 2D Avatars'},
				{image: '/image/nft/punk6.webp', title: 'Punks #36', text: 'NFT 2D Avatars'},
				{image: '/image/nft/punk1.webp', title: 'Punks #37', text: 'NFT 2D Avatars'},
				{image: '/image/nft/punk3.webp', title: 'Punks #38', text: 'NFT 2D Avatars'},
				{image: '/image/nft/punk2.webp', title: 'Punks #39', text: 'NFT 2D Avatars'},
				{image: '/image/nft/punk8.webp', title: 'Punks #40', text: 'NFT 2D Avatars'},
			]
		},
		{
			title: nftSection.daemons,
			items: [
				{image: '/image/nft/daemons11.webp', title: 'Demons #22', text: 'NFT 2D Avatars'},
				{image: '/image/nft/daemons7.webp', title: 'Demons #239', text: 'NFT 2D Avatars'},
				{image: '/image/nft/daemons1.webp', title: 'Demons #428', text: 'NFT 2D Avatars'},
				{image: '/image/nft/daemons4.webp', title: 'Demons #512', text: 'NFT 2D Avatars'},
				{image: '/image/nft/daemons3.webp', title: 'Demons #547', text: 'NFT 2D Avatars'},
				{image: '/image/nft/daemons8.webp', title: 'Demons #556', text: 'NFT 2D Avatars'},
				{image: '/image/nft/daemons5.webp', title: 'Demons #618', text: 'NFT 2D Avatars'},
				{image: '/image/nft/daemons10.webp', title: 'Demons #872', text: 'NFT 2D Avatars'},
				{image: '/image/nft/daemons9.webp', title: 'Demons #1268', text: 'NFT 2D Avatars'},
				{image: '/image/nft/daemons2.webp', title: 'Demons #1504', text: 'NFT 2D Avatars'},
				{image: '/image/nft/daemons6.webp', title: 'Demons #1971', text: 'NFT 2D Avatars'},
			]
		},
		{
			title: nftSection.builders,
			items: [
				{image: '/image/nft/build11.webp', title: 'Builders #1', text: 'NFT 2D Avatars'},
				{image: '/image/nft/build3.webp', title: 'Builders #11', text: 'NFT 2D Avatars'},
				{image: '/image/nft/build10.webp', title: 'Builders #12', text: 'NFT 2D Avatars'},
				{image: '/image/nft/build9.webp', title: 'Builders #16', text: 'NFT 2D Avatars'},
				{image: '/image/nft/build6.webp', title: 'Builders #42', text: 'NFT 2D Avatars'},
				{image: '/image/nft/build8.webp', title: 'Builders #143', text: 'NFT 2D Avatars'},
				{image: '/image/nft/build4.webp', title: 'Builders #233', text: 'NFT 2D Avatars'},
				{image: '/image/nft/build1.webp', title: 'Builders #262', text: 'NFT 2D Avatars'},
				{image: '/image/nft/build.webp', title: 'Builders #274', text: 'NFT 2D Avatars'},
				{image: '/image/nft/build5.webp', title: 'Builders #359', text: 'NFT 2D Avatars'},
				{image: '/image/nft/build7.webp', title: 'Builders #500', text: 'NFT 2D Avatars'},
			]
		},
		{
			title: nftSection.wolfWood,
			items: [
				{image: '/image/nft/ww1.webp', title: 'WoodWolves #1', text: 'NFT 2D Avatars'},
				{image: '/image/nft/ww2.webp', title: 'WoodWolves #2', text: 'NFT 2D Avatars'},
				{image: '/image/nft/ww3.webp', title: 'WoodWolves #3', text: 'NFT 2D Avatars'},
				{image: '/image/nft/ww4.webp', title: 'WoodWolves #4', text: 'NFT 2D Avatars'},
				{image: '/image/nft/ww5.webp', title: 'WoodWolves #5', text: 'NFT 2D Avatars'},
				{image: '/image/nft/ww6.webp', title: 'WoodWolves #6', text: 'NFT 2D Avatars'},
			]
		},
		{
			title: nftSection.wolfIron,
			items: [
				{image: '/image/nft/ws1.webp', title: 'SilverWolves #1', text: 'NFT 2D Avatars'},
				{image: '/image/nft/ws2.webp', title: 'SilverWolves #2', text: 'NFT 2D Avatars'},
				{image: '/image/nft/ws3.webp', title: 'SilverWolves #3', text: 'NFT 2D Avatars'},
				{image: '/image/nft/ws4.webp', title: 'SilverWolves #4', text: 'NFT 2D Avatars'},
				{image: '/image/nft/ws5.webp', title: 'SilverWolves #5', text: 'NFT 2D Avatars'},
				{image: '/image/nft/ws6.webp', title: 'SilverWolves #6', text: 'NFT 2D Avatars'},
				{image: '/image/nft/ws7.webp', title: 'SilverWolves #7', text: 'NFT 2D Avatars'},
				{image: '/image/nft/ws8.webp', title: 'SilverWolves #8', text: 'NFT 2D Avatars'},
				{image: '/image/nft/ws9.webp', title: 'SilverWolves #9', text: 'NFT 2D Avatars'},
				{image: '/image/nft/ws10.webp', title: 'SilverWolves #10', text: 'NFT 2D Avatars'},
				{image: '/image/nft/ws11.webp', title: 'SilverWolves #11', text: 'NFT 2D Avatars'},
			]
		},
	]
	const [currentTab, setCurrentTab] = useState(0)

	const handleStoryChange = (storyIndex: number) => {
		setCurrentTab(storyIndex)
	}

	return (
		<div className={style.playLearn} style={{ background: `url("/image/nft/nftBg.webp"), linear-gradient(359.95deg, #080912 0.04%, rgba(8, 9, 18, 0) 55.35%), linear-gradient(180deg, #0A0812 9.76%, rgba(8, 9, 18, 0) 68.29%)`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', paddingTop: 100, paddingBottom: 68, position: 'relative' }}>
			<div>
				<div className={style.intro}>
					<div className={[style.introContainer, 'lg:w-[50%!important]'].join(' ')}>
						<h2 className={style.title} data-aos-once="true" data-aos="slide-up">{nftSection.title}</h2>
						<div className={`md:flex relative z-10 hidden justify-center space-x-6 text-white flex-wrap`}>
							{videos.map((item, index) => (
								<div key={index} className="flex flex-col">
									<div
										className={`cursor-pointer font-Industry font-semibold md:text-base text-xs uppercase letter_space`}
										onClick={() => {
											index !== currentTab ? handleStoryChange(index) : null
										}}
									>{item.title}</div>
									<div className="mt-1 relative">
										<div
											style={{animationDuration: 5000 + "ms"}}
											className={`${index === currentTab ? "bg-[#D7FC51] h-[3px]" : ""}  w-full absolute z-10 my-auto top-0 bottom-0 -mt-2`}>
										</div>
									</div>
								</div>
							))}
						</div>
						<div className={`w-full md:hidden text-white grid grid-cols-3 gap-2 text-center mt-2`}>
							{videos.map((item, index) => (
								<div key={index} className={`flex flex-col ${index === videos.length - 1 ? 'col-span-1' : "col-span-1"}`}>
									<div
										className={`cursor-pointer font-Industry font-semibold text-xs uppercase letter_space`}
										onClick={() => {
											index !== currentTab ? handleStoryChange(index) : null
										}}
									>{item.title}</div>
									<div className="mt-1 relative w-full">
										<div
											style={{animationDuration: 5000 + "ms"}}
											className={`${index === currentTab ? "bg-[#D7FC51] h-[3px]" : ""}  w-full absolute z-10 my-auto top-0 bottom-0 -mt-2`}>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>

				<div
					data-aos-once="true"
					data-aos="fade-right"
					data-aos-offset="100"
					data-aos-easing="ease-in-sine"
					 className="flex items-center justify-center relative w-full h-full">
					<Swiper
						modules={[Navigation]}
						spaceBetween={12}
						slidesPerView={numOfSlides}
						centeredSlides={true}
						navigation={{
							nextEl: ".button-next-swiper-main",
							prevEl: ".button-prev-swiper-main",
						}}
						onSwiper={(swiper) => {
							//@ts-ignore
							setSlider(swiper)
						}}
						initialSlide={5}
						onSlideChange={(swiper) => {
							if (window.innerWidth >= 1280) {
								if (swiper.activeIndex + 1 === videos[currentTab].items.length + 11 || swiper.activeIndex + 1 === videos[currentTab].items.length - 11) {
									setCurrentIndex(videos[currentTab].items.length)
								} else setCurrentIndex((swiper.activeIndex - 4) % videos[currentTab].items.length)
							} else if (window.innerWidth >= 460) {
								if (swiper.activeIndex + 1 === videos[currentTab].items.length + 3) {
									setCurrentIndex(videos[currentTab].items.length)
								} else if (swiper.activeIndex === videos[currentTab].items.length - 3) {
									setCurrentIndex((swiper.activeIndex - 2) % videos[currentTab].items.length)
								} else if (swiper.activeIndex - 2 === 0) {
									setCurrentIndex(videos[currentTab].items.length)
								} else setCurrentIndex((swiper.activeIndex - 2) % videos[currentTab].items.length)
							} else if (window.innerWidth >= 320) {
								if (swiper.activeIndex === videos[currentTab].items.length) {
									setCurrentIndex(videos[currentTab].items.length)
								} else if (swiper.activeIndex === 0) {
									setCurrentIndex(videos[currentTab].items.length)
								} else setCurrentIndex((swiper.activeIndex) % videos[currentTab].items.length)
							}
						}}
						loop={true}
						style={{
							width: '90%',
							paddingTop: '40px',
							paddingBottom: '40px'
						}}
					>
						{videos[currentTab].items.map((item, index) => {
							return (
								<SwiperSlide key={index + 1}>
									<NftSlide
										index={index}
										title={item.title}
										text={item.text}
										image={item.image}
										indexPlanet={currentIndex}
									/>

								</SwiperSlide>
							)
						})}
					</Swiper>
					<div className="lg:block hidden absolute left-4">
						<img
							src="/image/leftGreenArrow.svg"
							alt=""
							style={{width: "40px", height: "40px"}}
							className="transform cursor-pointer button-prev-swiper-main z-5"
						/>
					</div>
					<div className="lg:block hidden absolute right-4">
						<img
							src="/image/leftGreenArrow.svg"
							alt=""
							style={{width: "40px", height: "40px"}}
							className="cursor-pointer rotate-180 button-next-swiper-main z-5"
						/>
					</div>
				</div>
				<div className="relative flex mb-16 mt-4 lg:hidden">
					<div className="w-10 absolute right-1/2 mr-5">
						<img
							src="/image/leftGreenArrow.svg"
							alt=""
							style={{width: "40px", height: "40px"}}
							className="transform cursor-pointer button-prev-swiper-main"
						/>
					</div>
					<div className="w-10 absolute left-1/2 ml-5">
						<img
							src="/image/leftGreenArrow.svg"
							alt=""
							style={{width: "40px", height: "40px"}}
							className="cursor-pointer rotate-180 button-next-swiper-main"
						/>
					</div>
				</div>

				{/* <div className="flex justify-center">
					<button
						className={`${style.button}`}
					>
						{nftSection.button}
					</button>
				</div> */}
			</div>

			<div className="fadeRevert" />
			<div className="fade" />
		</div>
	)
}

export default memo(NFT)
