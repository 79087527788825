interface INFTSlide {
	index: number;
	title: string;
	text: string;
	image: string;
	indexPlanet: number;
}

const PlanetSlide = ({title, text, image, indexPlanet, index}: INFTSlide) => {
	return (
		<>
			<div className={`transform transition-all duration-1000 
                  ${index + 1 === indexPlanet ? "px-3 py-3 border border-[#D7FC51] shadow-nft" : 'p-4 scale-90'} 
                   flex flex-col items-center bg-[#1F1F23] rounded relative`}>
				<div className={`transform transition-all duration-1000 relative mx-auto`}>
					<img src={image} width="100%" height="100%" alt=""/>
				</div>
				{index + 1 === indexPlanet && (
					<div className={`flex items-center justify-between w-full text-white font-Industry mt-2 whitespace-nowrap`}>
						<p>{title}</p>
						<p className="opacity-60 text-[14px]">{text}</p>
					</div>
				)}
			</div>
		</>
	)
}
export default PlanetSlide
